:root{
    --main: #1a1f29;
    --secondary: #fff;
    --tertiary:#0f1218;
    --quatenary:#242b39;
    --action: #D3AC2B;
}

.navbar {
    background-color: var(--tertiary);
    border-bottom: 2px solid var(--action);
}

.navbar-brand {
    color: var(--action);
}

.nav-link{
    color: var(--secondary);
    opacity: 0.5;
}

.nav-link:hover {
    color: var(--secondary);
    opacity: 1;
}

.nav-link.current{
    color: var(--secondary);
    opacity: 1;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}