@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
 
:root{
    --main: #1a1f29;
    --secondary: #FAF9F6;
    --tertiary:#0f1218;
    --action: #D3AC2B;
}
 
body {
    display: flex;
    flex-direction: column;
    background-color: var(--main);
    min-height: 100vh;
}
 
header {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: center;
    align-items: center;
    width:100vh;
}
 
h1, h2, h3, h4, p {
    color: var(--secondary);
    font-family: 'Lato', sans-serif;
}
 
h1 {
    font-size: 2.986rem;
    font-weight: 700;
}
 
h2 {
    font-size: 2.488rem;
}

h4 {
    margin: 0;
}
 

.btn.btn-primary{
    border: 0;
    background-color: var(--action);
    margin: 0;
}
 
.btn.btn-secondary{
    border-color: var(--action);
    color: var(--action);
    background-color: transparent;
}
 
.list-group-item{
    background-color: var(--tertiary) !important;
    color: var(--secondary) !important;
}
 
.list-group-item-action{
    background-color: var(--main);
}
 
.badge {
    background-color: var(--action);
}
 
.list-group-item a {
    color: white;        
    text-decoration: none; 
}
 
.list-group-item a:hover {
    color: rgb(173, 173, 173);        
    text-decoration: none; 
}
 
select {
    padding: 0 !important;
}

.calendar-form input,
.calendar-form button {
width: 100%; 
padding: 0.5rem;
margin-bottom: 1rem; 
}

.calendar-form button {
background-color: var(--action);
color: var(--secondary);
border: none;
cursor: pointer;
}

.add-calendar-form {
width: 100%; 
display: flex;
justify-content: center; 
margin: 1rem 0;
border-color: var(--secondary);
}

.calendar-form {
width: 75%; 
margin: auto; 
display: flex;
flex-direction: column;
align-items: center; 
gap: 10px;
}

.filters{
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
    align-items: center;
}

.form-select {
    background: var(--tertiary);
    padding: 0.5rem;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    border-radius: 0.25rem;
}

body {
    display: flex;
    flex-direction: column; /* maintains the document flow */
    min-height: 100vh; /* full viewport height */
    margin: 0; /* reset any default margin */
    position: relative;
    padding-bottom:3rem;
    box-sizing: inherit;
}

main {
    flex: 1;
}

html {
    height: 100%;
    box-sizing: border-box;
}

footer {
    background-color: var(--tertiary);
    height: 3rem;
    position: absolute;
    width: 100%;
    bottom: 0;
}

@media (max-width: 1024px){
    .calendar-form {
        width: 100%;
    }
}