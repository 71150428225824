@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root{
    --main: #1a1f29;
    --secondary: #fff;
    --tertiary:#0f1218;
    --action: #D3AC2B;
}

body {
    display: flex;
    flex-direction: column;
    background-color: var(--main);
    min-height: 100vh;
}

header {
    background-color: var(--tertiary);
}

p {
    margin: 0;
}

h1, h2, h3, h4, p, .card-title, label {
    color: var(--secondary);
}

@media (max-width: 991.98px) {
    .border-lg-1 {
        border: 0px;
    }

    body {
        background-color: var(--tertiary);
    }

    main {
        margin: 5vh 0;
    }

    .flexrow img {
        display: none;
    }

    .border-lg-1 {
        max-width: 400px;
    }

    .flexrow {
        display: flex;
        justify-content: center;
    }

}

@media (min-width: 991.98px) {
    main {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

    .flexrow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0% 10%;
    }
    
    .border-lg-1 {
        width: 500px
    }
    
}

.card {
    background-color: var(--tertiary);
    padding: 0;
}

.move-center {
    display: flex;
    justify-content: center;
}

.flex-horizontal {
    display: flex;
    flex-direction: row;
    padding: 8px 0 0 0;
}

.flex-horizontal a {
    margin-left: 8px;
    text-decoration: none;
    color: #00A8FC;
    font-weight: 500;
}

.btn.btn-primary{
    border: 0;
    background-color: var(--action);
    font-weight: 600;
}

.btn.btn-primary:hover {
    border: 0;
    background-color: var(--action);
    filter: brightness(90%);
}

.btn.btn-primary:active {
    border: 0;
    background-color: var(--action);
    filter: brightness(80%);
}

.btn.btn-secondary{
    border: 0;
    background-color: #00A8FC;
    font-weight: 600;
}

.btn.btn-secondary:hover {
    border: 0;
    background-color: #00A8FC;
    filter: brightness(90%);
}

.btn.btn-secondary:active {
    border: 0;
    background-color: #00A8FC;
    filter: brightness(80%);
}



.form-control {
    background-color: darkgrey;
    font-weight: 500;
    border: none;
    color: #434649;
}

.form-control:focus {
    background-color: darkgrey;
}

.container-lg {
    align-items: center;
}

.white {
    color: #fff;
}

h1, h2, h3, p {
    color: var(--secondary);
    font-family: 'Lato', sans-serif;
}

h1 {
    font-size: 2.986rem;
    font-weight: 700;
}

h2 {
    font-size: 2.488rem;
}

footer {
    background-color: var(--tertiary);
}