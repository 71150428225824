@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root {
    --main: #1a1f29;
    --secondary: #fff;
    --tertiary: #0f1218;
    --action: #D3AC2B;
    --feature-border: #b4b7ba;
}

body {
    display: flex;
    flex-direction: column;
    background-color: var(--main);
    min-height: 100vh;
    font-family: 'Lato', sans-serif;
}

.owner-container {
    background-color: var(--tertiary);
    color: var(--secondary);
    padding: 20px;
    border-radius: 8px;
    margin: 20px auto;
}

.owner-container h2,
.owner-container h3 {
    margin-bottom: 1rem;
}

.owner-container ul {
    list-style: none;
    padding-left: 0;
}

.owner-container li {
    background-color: var(--main);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr auto auto; 
    gap: 10px;
    align-items: center;
}

.owner-container input,
.owner-container select,
.owner-container button {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid var(--feature-border);
    background-color: var(--main);
    color: var(--secondary);
}

.owner-container button {
    background-color: var(--action);
    color: var(--secondary);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.owner-container input::placeholder,
.owner-container select {
    color: var(--secondary);
    opacity: 0.7;
}

.owner-container input[type="datetime-local"],
.owner-container input[type="number"],
.owner-container input[type="text"] {
    width: calc(100% - 20px); 
}

.owner-container .btn-delete {
    background-color: #e74c3c; 
}

.owner-container .btn-delete:hover {
    background-color: darken(#e74c3c, 10%);
}

@media (max-width: 768px) {
    .owner-container {
        width: 90%; 
        padding: 10px; 
    }
}

.owner-container button:not(:last-child) {
    margin-right: 10px;
}

.owner-contact-form,
.owner-timeslot-form {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;
}

.owner-timeslot-details {
    display: flex;
    flex-wrap: wrap; 
    gap: 10px;
    align-items: center;
}

.owner-timeslot-controls {
    display: flex;
    gap: 10px;
}

.owner-container input,
.owner-container select,
.owner-container button {
    height: 38px;
}

.mt-1 { margin-top: 1rem; }
.mb-1 { margin-bottom: 1rem; }
.p-1 { padding: 1rem; }
.text-center { text-align: center; }
.flex { display: flex; }
.flex-column { flex-direction: column; }
.align-center { align-items: center; }
.justify-between { justify-content: space-between; }
.w-100 { width: 100%; }

h1 {
    display:none;
}

.owner-container .green-btn {
    background-color: #008000; 
}

.owner-container .green-btn:hover {
    background-color: darken(#008000, 10%);
}