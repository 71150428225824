@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root{
    --main: #1a1f29;
    --secondary: #fff;
    --tertiary:#0f1218;
    --quatenary:#242b39;
    --action: #D3AC2B;
}

body {
    display: flex;
    flex-direction: column;
    background-color: var(--main);
    min-height: 100dvh;
}

header {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: center;
    align-items: center;
    width:100vh;
}

main {
    flex: 1;
}

h1, h2, h3, p {
    color: var(--secondary);
    font-family: 'Lato', sans-serif;
}

h1 {
    font-size: 2.986rem;
    font-weight: 700;
}

h2 {
    font-size: 2.488rem;
}


.btn.btn-primary{
    border: 0;
    background-color: var(--action);
}

.btn.btn-secondary{
    border-color: var(--action);
    color: var(--action);
    background-color: transparent;
}


.list-group-item{
    background-color: var(--quatenary) !important;
    color: var(--secondary) !important;
}

.list-group-item:hover{
    opacity: 0.8;
}

.badge {
    background-color: var(--action);
}

footer {
    background-color: var(--tertiary);
}



